@import url('https://fonts.googleapis.com/css2?family=Vollkorn:wght@600&display=swap');
.eligible {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    padding: 20px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.eligible h1 {
    font-size: 4rem;
    font-family: 'Playfair Display', serif;
    color: #644222;
}

.eligible p {
    padding-top: 2%;
    font-size: 2rem;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
}

.eligible button {
    margin-top: 5%;
    width: max-content;
    background-color: #644222;
    border-radius: 3vh;
    border: 3px dashed rgb(0, 0, 0);
    color: white;
    font-size: 2rem;
    padding: 10px;
    font-family: 'Vollkorn', serif;    
}

.eligi {
    margin-top: 5%;
    /* background-image: url('../images/image6.png'); */

    background-color: antiquewhite;
    background-size: cover;
    /* Adjust the image size */
    background-repeat: no-repeat;
    /* Prevent repeating the image */
    background-position: center center;

}

@media (max-width:768px){

 
    .eligible h1{
        font-size: 2.3rem;
        text-align: center;
    }
    .eligible p{
        font-size: 1rem;
    }
}