.eligible button {
    margin-top: 5%;
    width: max-content;
    background-color: #644222;
    border-radius: 2vh;
    border: 3px dashed rgb(0, 0, 0);
    color: white;
    font-size: 2rem;
    padding: 10px;
    font-family: 'Vollkorn', serif;  
    cursor: pointer;  
}

@media (max-width:370px){
    .eligible button{
        font-size: 1.7rem;
    }
}
