.offerr{
    max-width: 900px;
    background-color:bisque;
    margin: 0 auto;
    border: 5px dashed #644222;
}

.offerr h1{
    font-size: 1.4rem;
    text-align: center;
    font-family: 'Playfair Display', serif;
}

@media (max-width:768px){
    .offerwrap{
        padding: 20px;
    }
    .offerr h1{
        font-size: 0.8rem;
    }
}