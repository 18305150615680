.infobg{
    background-color:antiquewhite;
    max-width: max-content;
    margin: 0 auto;
    font-family: 'Playfair Display', serif;
    border: 5px solid #644222;
    margin-top: 5%;
}

.infop{
    padding: 5px;
}
.infowrap{
    text-align: center;
    padding: 5px;
    margin-top: 10%;
    font-family: 'Playfair Display', serif;

}
.custom-list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-weight: 900;
    /* align-items: center; */
  }

  .custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1.4;
  }

  .custom-list li::before {
    content: "✓"; /* You can replace this with your preferred bullet symbol or image */
    margin-right: 8px;
    color: #644222;
    padding:5px;
  }

  .infobg h3, p{
    text-align: center;
    font-size: 2rem;
  }