.formcontainer {
    margin: 0 auto;
    box-shadow: 1px 1px 10px rgb(52, 52, 52);
    border-radius: 3vh;
    border: 10px solid #644222;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 550px;
    margin-top: 5%;
}

.formcontainer h1{
    text-align: center;
    font-family: 'Playfair Display', serif;
}

.formcontainer p{
    text-align: center;
}

.formquestion {
    max-width: 500px;
    padding: 5vh;
    padding-bottom: 0;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.formoption {
    margin-top: 10%;
}

.options {
    display: flex;
    margin-bottom: 10px;
    margin-top: 5%;
    padding: 10px;
    background-color: beige;
    font-size: 0.8rem;
    font-weight: bold;
}

.fquess {
    font-size: 1rem;
}

.options input[type="radio"] {
    vertical-align: middle;
    margin-right: 10px;
    display: inline-block;
    /* Adjust this value to control the spacing */
}


.formbuttons {
    display: flex;
    justify-content: space-between;
    /* Add this to space out the buttons */
    width: 100%;
    /* Ensure the buttons span the full container width */
}



.formbuttons button {
    padding: 6px;
    width: 200px;
    background-color: #644222;
    color: white;
    font-size: 1.6rem;
    border: 0px solid #644222;
    margin-bottom: 0;
    margin-top: 5%;
    font-family: 'Playfair Display', serif;
}

.butt1 {
    border-radius: 0px 20px 0px 0px;
}

.butt2 {
    border-radius: 20px 0px 0px 0px;

}