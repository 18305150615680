@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');
.implantwrap{
    max-width: min-content;
    margin: 0 auto;
    margin-top: 5%;
}

.implantwrap h1{
    text-align: center;
    font-family: 'Playfair Display', serif;
}

.implantcard img{
    width: 300px;
    margin: 0 auto;
}
.implantcard{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 500px;
    border: 2px solid black;
    padding: 20px;
    background-color: antiquewhite;
    border-radius: 2vh;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.433);
}

.implantul{
    list-style: none;
    padding-left: 0;
}
.implantcard p{
    font-family: 'Alice', serif;
    font-weight: 900;
    font-size: 1.2rem;
}
.implantul li{
    line-height: 1.4;
    font-family: 'Alice', serif;
}
.implantgrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width:768px){
    .implantgrid{
        grid-template-columns: 1fr;
    }
    .implantcard{
        width: 300px;
    }
    .implantwrap{
        margin-top: 20%;
    }
}