.wrapb{
    max-width: 1440px;
    margin: 0 auto;
}

.beforegrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    
}
.imag{
    margin: 0 auto;
}
.imag img{
    height: 350px;
    width: 400px;
    border: 5px solid burlywood;
    margin: 20px;
    margin-left: 2px;
    margin-right: 5px;
    border-radius: 2rem;
}

.explore{
    font-family: 'Playfair Display', serif;
    margin-top: 90px;
}


.servicescontent p{
    font-weight: 900;
}


.afterheadings{
    margin-top: 5%;
    text-align: center;
}


.theservices{
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
}
.servicesdes{
    font-family: 'Playfair Display', serif;
}

@media (max-width:768px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
   
    .imag img{
        height: 280px;
        width: 350px;
    }
    .servicescontent{
        padding: 5px;
    }
    .theservices{
        font-family: 'Playfair Display', serif;
        font-size: 3rem;
    }
}

@media (max-width:360px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
    .imag img{
        height: 280px;
        width: 330px;
    }
}
@media (max-width:330px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
    .imag img{
        height: 280px;
        width: 310px;
    }
}
@media (max-width:320px){
    .beforegrid{
        grid-template-columns: 1fr;
    }
    .imag img{
        height: 280px;
        width: 300px;
    }
}