@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

.ifwrap {
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 5%;
    padding: 20px;
}

.ifh1 {
    font-size: 2rem;
    font-family: 'Playfair Display', serif;
    text-align: center;

}

.if {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 0.1fr;
}

.ifimg img {
    height: 400px;
}

.ifli {
    padding-left: 20px;
    margin-bottom: 10px;
    background: url('../images/spdlogo.png') no-repeat left center;
    background-size: 16px 16px;
    /* Adjust the size of the bullet image */
    line-height: 1.4;
    margin-top: 20px;
    justify-content: center;
    font-size: 1.4rem;
}

.iful {
    padding-top: 1%;
    list-style-type: none;
    padding-left: 0;
}

.ili {
    text-align: center;
    font-size: 3.2rem;
    font-family: 'Playfair Display', serif;
}

@media (max-width:768px){


    .if{
        grid-template-columns: 1fr;
    }
    .ifimg img {
        height: 250px;
    }
    .ifli{
        padding-right: 30px;
    }

}
@media (max-width:350px){
    .ifimg img {
        height: 200px;
    }
}


h1 {
    font-size: 2rem;
    /* font-family: 'Courier New', Courier, monospace; */
    font-family: 'Playfair Display', serif;


}