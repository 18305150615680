.quotee {
    background-image: url('../images/dentistequipment.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: antiquewhite;
    background-blend-mode: multiply;
    margin-bottom: 10%;
    margin-top: 5%;
}

.ili1{
    padding-top: 10%;
}

.ili1 {
    font-family: 'Shadows Into Light', cursive;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
}
.iliimg{
    height: 80px;
}

.qwrap{
    padding-top: 5%;
}

.qb{
    
    padding-bottom: 5%;
}

@media (max-width:768px){
    .quotee {
        background-image: url('../images/dentistequipment.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

    }
    .ili1{
        font-size: 1.2rem;
    }

}