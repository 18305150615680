.pbg{
    background-color: rgb(250, 250, 250);
}
.pgh1{
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    margin: 0 auto;
}

.pgp{
    text-align: center;
    font-size: 1.4rem;
    font-weight: 900;

}

.pcardwrap{
    max-width: 1200px;
    margin: 0 auto;
}
.pgrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.pcard{
    width: 500px;
    background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 3vh;
    margin: 0 auto;
    border: 3px solid #644222;

}

.pcard h1{
    text-align: center;
}

.pcard img{
    width: 300px;
    padding: 0;
    border-radius: 3vh;
}

.pul {
    padding-top: 1%;
    padding-left: 10px;
    font-weight: 600;
}

.pli {
    padding-left: 32px;
    line-height: 1.4;
    margin-top: 20px;
    font-size: large;
}
.pcard1wrap{
    margin-top: 20px;
}
.pcard1{
    background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 3vh;
    border: 3px solid #644222;

}

.pcard1 h1{
    text-align: center;
}

.pcard1 img{
    width: 300px;
    padding: 0;
    border-radius: 3vh;
}




@media (max-width:768px){
    .pgh1{
        font-size: 1rem;
    }

    .pgrid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .pcard{
        width: 300px;
        background-color: antiquewhite;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-radius: 3vh;
        margin: 0 auto;
        border: 3px solid #644222;
    
    }
    .pcard1{
        background-color: antiquewhite;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        border-radius: 3vh;
        border: 3px solid #644222;
        width: 300px;
    
    }
  
}