.headerr{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 10px;
    padding-left: 90px;
    padding-right: 60px;
    
}

.headp a{
    text-decoration: none;
    color: white;
}

@media (max-width:768px){
    .headerr{
        flex-direction: column;
        padding: 0;
        padding-top: 10px;
    }
}
.headlogo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headlogo p{
    font-family: 'Vollkorn', serif;  
    font-size: 1.4rem;
    text-align: center;

}

.headimg{
    height: 150px;
}

.headbg{
    background-color: #56392b;
}

.headerr img{
    height: 50px;
    width: 50px;
}
.headh1{
    font-size:1rem ;
    font-weight: 900;
}
.headh{
    display: flex;
    align-items: center;
    
}
.headddp{
    display: flex;
    align-items: center;
    padding-left: 15px;
}
.headcontent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.headp{
    padding-left:10px ;
    font-weight:900 ;
    font-size: 1.4rem;
}
