.eligiblebut{
    position: static;
    background-color: bisque;
    margin-top: 5%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;

}

.eligiblebut p{
    text-align: center;
    font-weight: 900;
}

.eligiblebut button{
    font-size: 3rem;
    padding: 5px;
    background-color: #644222;
    color: white;
    width: 80%;
    border: 2px solid black;
    cursor: pointer;
    font-family: 'Vollkorn', serif; 
}

@media (max-width:768px){
    .eligiblebut button{
        font-size: 1rem;
    }
    .eligiblebut p{
        font-size:0.7rem ;
    }
}