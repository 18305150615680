.whybg{
    max-width: 1440px;
    background-color: #FFFFFF;
    margin: 0 auto;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 5%;
}

.whyheading{
    text-align: center;
    margin-bottom: 5%;
}
.whyheading h1{
    font-family: 'Playfair Display', serif;
}
.whyheading p{
    font-weight: 900;
}
.whygrid{
    display: grid;
    grid-template-columns: 1fr repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 50px;
}

@media (max-width:768px){
    .whygrid{
        grid-template-columns: 1fr;
    }
}